/* You can add global styles to this file, and also import other style files */

// /* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "@fontsource/playfair-display";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "scss/custom.scss";
@import "node_modules/bootstrap/scss/bootstrap";

