$header_font: "Playfair Display";
$primary_font: "Roboto";

$background_color: white;
$border_color: #d9e2ed;

$primary_color: #1b365d; //#2d3f5b;
$primary: #1b365d; //;#2d3f5b;
$primaryDark: #096452;

$pirmaryDarker: #014d40;
$primaryLight: #c5f7e2;
$primaryLighter: #e4f7ee;

$secondary: #b5fbee;
$secondaryDark: #a5e6da;

$secondaryLighter: #fff3c4;
$secondaryLight: #fce588;
$secondaryDarker: #f0b429;

$atheneaBlue: #66ccff;

$tertiary: #eeeeee;
$goldenRod: #0ab292; //#b5fbee;//

$theme-colors: (
  "primary": $primary,
  "primaryLight": $primaryLight,
  "primaryLighter": $primaryLighter,
  "primaryDark": $primaryDark,
  "pirmaryDarker": $pirmaryDarker,
  "secondary": $secondary,
  "secondaryLighter": $secondaryLighter,
  "secondaryLight": $secondaryLight,
  "secondaryDark": $secondaryDark,
  "secondaryDarker": $secondaryDarker,
  "tertiary": $tertiary,
  "goldenRod": $goldenRod,
  "danger": #ff4136,
  "lightblue": $border_color,
  "backgroundColor": $background_color,
  "atheneaBlue": $atheneaBlue,
);
:root {
  --primary: $primary;
  --secondary: $secondary;
}

body {
  color: $primary_color;
  font-family: $primary_font;
  letter-spacing: 0.2px !important;
  background-color: $background_color;
}
.container-fluid {
  padding-top: 15px;
  padding-bottom: 15px;
}

.bg-gray {
  background-color: $tertiary;
}
.bg-smoke {
  background-color: whitesmoke;
}
.card-body{
  overflow: hidden !important;
}
h1 {
  letter-spacing: 0.1px !important;
  font-size: 2.8rem;
  line-height: 3rem;
  color: $primary_color;
  font-family: $header_font;
}

@media screen and (max-width: 525px) {
  h1 {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 360px) {
  h1 {
    font-size: 2rem;
  }
}
h2 {
  color: $primary_color;
  font-family: $header_font;
}
h3 {
  color: $primary_color;
  font-family: $header_font;
}
h4 {
  color: $primary_color;
  font-family: $header_font;
}
.body-font {
  font-family: $primary_font !important;
}
.display-4 {
  font-size: 2.8rem;
}
p {
  letter-spacing: 0.1px !important;
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

label {
  float: left;
  text-align: left;
  font-weight: 500;
}

.text-secondaryDark {
  color: var(--secondaryDark);
}
.text-goldenRod {
  color: var(--goldenRod);
}

.title2 {
  line-height: 1.2;
}

.title2 {
  font-weight: 500;
  margin: 0 0 0.75rem;
  font-size: 1.75rem;
}

.panel {
  margin: 0 auto;
  max-width: 26.875rem;
  padding: 3rem;
  text-align: center;
  background-color: #fff;
  border: 1px solid $border_color;
  border-radius: 4px;
}

.customTitle {
  align-items: flex-end;
  // min-width: 100%;
  font-weight: 600 !important;
  // text-align: start;
  text-overflow: i;
  white-space: normal;
  font-size: 2rem !important;
  letter-spacing: -1px !important;
  line-height: 2.25rem !important;
  color: var(--ion-color-tertiary);
}

.headerTop {
  z-index: 11 !important;
}

.headerBottom {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  ion-toolbar {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    h2 {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }
}

.split-pane-md.split-pane-visible > .split-pane-side {
  width: auto;
  min-width: 20rem !important;
  max-width: 20rem !important;
}

/**
* BUTTONS
**/

// .btn {
//     border: 2px solid transparent;
//     border-radius: 2px;
//     cursor: pointer;
//     display: inline-block;
//     font: inherit;
//     line-height: 1.5;
//     overflow: visible;
//     padding: 0.75rem 1.5rem;
//     text-align: center;
//     text-decoration: none;
//     &.secondary {
//         color: white;
//         background-color: $primary;
//     }
// }

.btn-outline {
  &.light {
    color: white;
    border-color: white;
    background-color: transparent;
  }
}

.btn-outline:hover {
  &.light {
    color: $primary;
    border-color: white;
    background-color: white;
  }
}
/**
* PAGE SECTIONS
**/

/* NAVBAR */
nav {
  color: $primary !important;
  &.bg-dark {
    background-color: $primary !important;
  }
  &.bg-white {
    background-color: white !important;
    color: $primary !important;
  }
}

.link {
  color: $primary;
  border-bottom: 1px solid $primary;
  cursor: pointer;
}

.link:hover {
  border-bottom: 1px solid $primaryDark;
  color: $primaryDark;
  cursor: pointer;
  text-decoration: none;
}
.navbar-light {
  a {
    color: $primary !important;
  }
  a:hover {
    color: $primary !important;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
  a:active {
    background-color: $secondary;
  }
}

/* ORDER LIST */
.order-list {
  width: 100%;
  max-width: 100%;
  border: solid 1px $background_color;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.order-box {
  padding: 20px 10px;
  border-bottom: solid 1px $background_color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;

  &.new {
    border: dashed 1px $primary !important;
    min-height: 60px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.order-box:last-child {
  border: none;
}

.order-list.cdk-drop-list-dragging .order-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.order-custom-placeholder {
  background: $background_color;
  border: dashed 2px $primary;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* CARDS */
.card-body {
  overflow: scroll;
}

.section {
  padding-top: 5rem;
  padding-bottom: 4rem;
  &.first {
    padding-bottom: 1rem;
    padding-top: 4rem;
  }
  &.wave {
    padding-top: 4rem;
  }
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-gradient {
  background: linear-gradient(180deg, white, var(--tertiary));
}
.bg-tertiary-gradient {
  background: linear-gradient(180deg, var(--tertiary), white);
}
/* text */
.text-primary {
  color: $primary !important;
}

// paddings
.pb-5{
  padding-bottom: 5rem !important;
}
.pb-6{
  padding-bottom: 6rem !important;
}
.pb-7{
  padding-bottom: 7rem !important;
}
.pb-8{
  padding-bottom: 8rem !important;
}
// margins

// margins
// mt positive
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}
//mt negative
.mt--3 {
  margin-top: -3rem;
}
.mt--4 {
  margin-top: -4rem;
}
.mt--5 {
  margin-top: -5rem;
}
.mt--6 {
  margin-top: -6rem;
}
.mt--7 {
  margin-top: -7rem;
}
.mt--8 {
  margin-top: -8rem;
}
.mt--9 {
  margin-top: -9rem;
}
.mt--10 {
  margin-top: -10rem;
}
.mt--15 {
  margin-top: -15rem;
}

//mb positive
.mb-6 {
  margin-bottom: 6rem !important;
}
.mb-7 {
  margin-bottom: 7rem !important;
}
.mb-8 {
  margin-bottom: 8rem !important;
}

html,
body {
  max-width: 100%;
}
body {
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
}


.ib-container {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: cover;
  min-height: 500px;
  max-height: 700px;
  .ib-box {
      position: absolute;
      bottom: 0;
      left: 0;
  }
}
