// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$atheneasolutions-primary: mat-palette($mat-indigo);
$atheneasolutions-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$atheneasolutions-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$atheneasolutions-theme: mat-light-theme((
  color: (
    primary: $atheneasolutions-primary,
    accent: $atheneasolutions-accent,
    warn: $atheneasolutions-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($atheneasolutions-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "", "Roboto", sans-serif;
  --ion-text-color: var(--ion-color-tertiary) !important;

  /** primary **/
  --ion-color-primary: #1f3057;
  --ion-color-primary-rgb: 23, 108, 82;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #145f48;
  --ion-color-primary-tint: #2e7b63;

  /** secondary **/
  --ion-color-secondary: #a5e3d6;
  --ion-color-secondary-rgb: 228, 247, 238;
  --ion-color-secondary-contrast: #176c52;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #c9d9d1;
  --ion-color-secondary-tint: #e7f8f0;

  /** tertiary **/
  --ion-color-tertiary: #eeeeee;
  --ion-color-tertiary-rgb: 16, 42, 67;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #0e253b;
  --ion-color-tertiary-tint: #283f56;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** custom-hover **/
  --ion-color-custom-hover: #e5f3fe;
  --ion-color-custom-hover-rgb: 229, 243, 254;
  --ion-color-custom-hover-contrast: #000000;
  --ion-color-custom-hover-contrast-rgb: 0, 0, 0;
  --ion-color-custom-hover-shade: #cad6e0;
  --ion-color-custom-hover-tint: #e8f4fe;

  /** yellow button **/
  --ion-color-yellow-button: #fadb5f;
  --ion-color-yellow-button-rgb: 250, 219, 95;
  --ion-color-yellow-button-contrast: #000000;
  --ion-color-yellow-button-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-button-shade: #dcc154;
  --ion-color-yellow-button-tint: #fbdf6f;

  /** yellow hover button **/
  --ion-color-yellow-hover-button: #f7c948;
  --ion-color-yellow-hover-button-rgb: 247, 201, 72;
  --ion-color-yellow-hover-button-contrast: #000000;
  --ion-color-yellow-hover-button-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-hover-button-shade: #d9b13f;
  --ion-color-yellow-hover-button-tint: #f8ce5a;

  /** disabled button **/
  --ion-color-disabled-button: #ebf0f5;
  --ion-color-disabled-button-rgb: 235, 240, 245;
  --ion-color-disabled-button-contrast: #000000;
  --ion-color-disabled-button-contrast-rgb: 0, 0, 0;
  --ion-color-disabled-button-shade: #cfd3d8;
  --ion-color-disabled-button-tint: #edf2f6;

  /** green hover button **/
  --ion-color-green-hover-button: #0a6452;
  --ion-color-green-hover-button-rgb: 10, 100, 82;
  --ion-color-green-hover-button-contrast: #ffffff;
  --ion-color-green-hover-button-contrast-rgb: 255, 255, 255;
  --ion-color-green-hover-button-shade: #095848;
  --ion-color-green-hover-button-tint: #237463;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-custom-hover {
  --ion-color-base: var(--ion-color-custom-hover);
  --ion-color-base-rgb: var(--ion-color-custom-hover-rgb);
  --ion-color-contrast: var(--ion-color-custom-hover-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-hover-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-hover-shade);
  --ion-color-tint: var(--ion-color-custom-hover-tint);
}

.ion-color-yellow-button {
  --ion-color-base: var(--ion-color-yellow-button);
  --ion-color-base-rgb: var(--ion-color-yellow-button-rgb);
  --ion-color-contrast: var(--ion-color-yellow-button-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-button-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-button-shade);
  --ion-color-tint: var(--ion-color-yellow-button-tint);
}

.ion-color-disabled-button {
  --ion-color-base: var(--ion-color-disabled-button);
  --ion-color-base-rgb: var(--ion-color-disabled-button-rgb);
  --ion-color-contrast: var(--ion-color-disabled-button-contrast);
  --ion-color-contrast-rgb: var(--ion-color-disabled-button-contrast-rgb);
  --ion-color-shade: var(--ion-color-disabled-button-shade);
  --ion-color-tint: var(--ion-color-disabled-button-tint);
}

.ion-color-yellow-hover-button {
  --ion-color-base: var(--ion-color-yellow-hover-button);
  --ion-color-base-rgb: var(--ion-color-yellow-hover-button-rgb);
  --ion-color-contrast: var(--ion-color-yellow-hover-button-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-hover-button-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-hover-button-shade);
  --ion-color-tint: var(--ion-color-yellow-hover-button-tint);
}

.ion-color-green-hover-button {
  --ion-color-base: var(--ion-color-green-hover-button);
  --ion-color-base-rgb: var(--ion-color-green-hover-button-rgb);
  --ion-color-contrast: var(--ion-color-green-hover-button-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-hover-button-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-hover-button-shade);
  --ion-color-tint: var(--ion-color-green-hover-button-tint);
}
